<template>
    <div>
        Placeholder for Admin
    </div>
</template>

<script>
export default {
  name: 'admin-home'
}
</script>
